<template>
  <div class="checkPending" style="text-align: left;">
    <van-nav-bar
        title="区域审核中"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />

    <div style="padding: 30px;">
      <van-cell-group>
        <van-field label="城市经理" value="审核中，请等待..." readonly v-if="status == -1" />
        <van-field label="区域经理" value="审核中，请等待..." readonly v-if="status == -2" />
        <van-field label="城市经理" value="已被驳回，请重新设置." readonly v-if="status == -7" />
        <van-field label="区域经理" value="已被驳回，请重新设置." readonly v-if="status == -8" />
        <van-field label="当前状态" value="账号已被停用，暂时无法使用." readonly v-if="status == 0" />
        <van-field label="驳回理由" :value="rejectRemark" readonly v-if="!!rejectRemark" />
      </van-cell-group>
      <br />
      <van-button type="info" round block @click="doCheck">重新检查状态</van-button>
      <van-button v-if="status == -7 || status == -8" type="primary" round block @click="doReset" style="margin-top: 15px;">重新设置区域信息</van-button>
    </div>
  </div>
</template>

<script>
import { getInfo, getRejectRemark } from "@/api/login";
import {Notify} from "vant";

export default {
  name: "CheckPending",
  components: {},
  data() {
    return {
      phone: this.$route.query.phone,
      status: this.$route.query.status,
      rejectRemark: '',
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if(this.status == '-9') {
        // 回去设置吧（一般不会出现）
        this.$router.push({path: '/setRegionManager',query: { phone: this.$route.query.phone, status: this.$route.query.status }});
      } else if(this.status == '1') {
        this.$router.push({path: '/'});
      } else if(this.status == '-7' || this.status == '-8') {
        // 获取最新的一条驳回理由
        this.setRejectRemark();
      }
    },
    doCheck() {
      // console.log(this.phone);
      getInfo().then(response => {
        console.log(response);
        let data = response.data;
        this.rejectRemark = '';
        if(data.status == 1) {
          this.$router.push({path: '/'});
        } else if(data.status == -1) {
          Notify({ type: 'danger', message: '待城市经理审核中,请等待...' });
        } else if(data.status == -2) {
          Notify({ type: 'danger', message: '待区域经理审核中,请等待...' });
        } else if(data.status == -7) {
          Notify({ type: 'danger', message: '已被城市经理驳回,请重新设置.' });
          this.setRejectRemark();
        } else if(data.status == -8) {
          Notify({ type: 'danger', message: '已被区域经理驳回,请重新设置.' });
          this.setRejectRemark();
        } else if(data.status == -9) {
          // 回去设置吧（一般不会出现）
          this.$router.push({path: '/setRegionManager',query: { phone: this.$route.query.phone, status: this.$route.query.status }});
        } else if(data.status == 0) {
          Notify({ type: 'danger', message: '您的账号已被停用，暂时无法使用.' });
        }
      });
    },
    setRejectRemark() {
      getRejectRemark().then(r => {
        console.log(r);
        this.rejectRemark = r.data;
      });
    },
    doReset() {
      this.$router.push({path: '/setRegionManager',query: { phone: this.$route.query.phone, status: this.$route.query.status }});
    },
    onReturn() {
      this.$router.push({path: '/phoneInput',query: { phone: this.$route.query.phone }});
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
